import React from 'react'

import './Footer.sass'

const Footer = () => {
  return (
    <div id="Footer">
      <div className="-wrapper">
        Origami Studio © 2016 - {(new Date().getFullYear())}
      </div>
    </div>
  )
}

export default Footer
