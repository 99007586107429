import React from 'react'

import './Header.sass'

const Header = () => {
  return (
    <div id="Header">
      <div className="-wrapper">
        <div className="logoContainer">
          <img src={require('../assets/img/logo.svg')} width="300" alt="Origami"/>
        </div>
      </div>
    </div>
  )
}

export default Header
