import React from 'react'

import './Tiles.sass'

import Tile from './Tile'

const projects = [
  {
    title: 'Сайт агропромышленного холдинга Olzha Agro',
    subtitle: 'Разработка',
    link: 'https://olzhaagro.kz',
    img: require('../assets/img/projects/olzhaagro.png')
  },
  {
    title: 'Аналитический сайт IPO FIT',
    subtitle: 'Дизайн и разработка',
    link: 'https://ipofit.com',
    img: require('../assets/img/projects/ipofit.png')
  },
  {
    title: 'Сайт жилого комплекса Lumin House',
    subtitle: 'Разработка',
    link: 'https://lumin.origami.kz',
    img: require('../assets/img/projects/lumin.png')
  },
  {
    title: 'Сайт Лиги Молодых Избирателей',
    subtitle: 'Разработка',
    link: 'https://vibory.watch',
    img: require('../assets/img/projects/vibory.png')
  },
  {
    title: 'Сайт фестиваля MediaCAMP Fest',
    subtitle: 'Разработка',
    link: 'https://mediafest.asia',
    img: require('../assets/img/projects/mediafest.png')
  },
  {
    title: 'Сайт студии дизайна IGRAPHIC',
    subtitle: 'Разработка',
    link: 'https://igraphic.kz',
    img: require('../assets/img/projects/igraphic.png')
  },
  {
    title: 'Социальный проект для Tengrinews',
    subtitle: 'Разработка',
    link: 'https://whatif.origami.kz',
    img: require('../assets/img/projects/whatif.png')
  },
  {
    title: 'Сайт KFC',
    subtitle: 'Участие в разработке',
    link: 'https://kfc.ru',
    img: require('../assets/img/projects/kfc.png')
  },
  {
    title: 'Сайт Газпрома',
    subtitle: 'Участие в разработке',
    link: 'https://gazprom.ru',
    img: require('../assets/img/projects/gazprom.png')
  },
  {
    title: 'Сайт Альфабанка',
    subtitle: 'Участие в разработке',
    link: 'https://alfabank.ru',
    img: require('../assets/img/projects/alfabank.png')
  },
  {
    title: 'Сайт тур-агенства AstanaGuide',
    subtitle: 'Дизайн и разработка',
    link: 'http://astanaguide.origami.kz',
    img: require('../assets/img/projects/astanaguide.png')
  },
  {
    title: 'Сайт Казпочты',
    subtitle: 'Участие в разработке',
    link: 'https://post.kz',
    img: require('../assets/img/projects/post.png')
  },
  {
    title: 'Сайт для Samal Motors',
    subtitle: 'Дизайн и разработка',
    link: 'https://samalmotors.origami.kz',
    img: require('../assets/img/projects/samalmotors.png')
  },
  // {
  //   title: 'Сайт iHappiness',
  //   subtitle: 'Дизайн и разработка',
  //   link: 'http://ihappiness.kz',
  //   img: require('../assets/img/projects/ihappiness.png')
  // },
  {
    title: 'Сайт для Technogym',
    subtitle: 'Дизайн и разработка',
    link: 'http://technogym.origami.kz',
    img: require('../assets/img/projects/technogym.png')
  },
  {
    title: 'Сайт мебельного салона Pappa Carrlo',
    subtitle: 'Дизайн и разработка',
    link: 'http://pappa-carrlo.origami.kz',
    img: require('../assets/img/projects/pappa-carrlo.png')
  }
]

const Tiles = () => {
  return (
    <div id="Tiles">
      {projects.map((project, index) =>
        <Tile {...project} key={index}/>
      )}
    </div>
  )
}

export default Tiles
