import React from 'react'

import './App.sass'

import Header from './components/Header'
import Tiles from './components/Tiles'
import Footer from './components/Footer'

function App() {
  return (
    <div className="App">
      <Header/>
      <div className="body">
        <div className="-wrapper">
          <Tiles/>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default App
