import React from 'react'

import './Tile.sass'

const Tile = props => {
  return (
    <a href={props.link} target="_blank" rel="noopener noreferrer" id="Tile">
      <div className="image">
        <img src={props.img} width="100%" alt=""/>
      </div>
      <div className="title">{props.title}</div>
      <div className="subtitle">{props.subtitle}</div>
    </a>
  )
}

export default Tile
